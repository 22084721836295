import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { getCertificatesRequest } from "../../../services/DriverCardService"
import { SearchIcon } from "../assets/SearchIcon"
import { getEmployeesRequest } from "../../../services/CompanyService"

const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const formControlLabelStyle = {
    '& .MuiFormControlLabel-label': {
        width: '268px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const DriverFioFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [driverFios, setDriverFios] = useState<any>({})
    const [search, setSearch] = useState<string>('')

    const role = 'Водитель'
    const [driverIds, setDriverIds] = useState<any>([])

    useEffect(()=>{(async()=>{
        const response = await getEmployeesRequest(1, role, search)
        setDriverFios(
            response.data.data
            .map((driver: any) => driver.lastname + ' ' + driver.firstname + ' ' + driver.middlename)
            .reduce((obj: any, item: any) => ({...obj, [item]: false}), {})
        )
        setDriverIds(response.data.data.map((driver: any) => driver.id))
        console.log(response)
    })()},[search])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDriverFios({...driverFios, [event.target.name]: event.target.checked})
    }

    const handleConfirmFilter = () => {
        //const resultArray = Object.keys(medicFios).filter((key) => medicFios[key] === true)
        //console.log(resultArray)
        const keys = Object.keys(driverFios)
        const resultArray = driverIds.filter((id:any, index:any) => driverFios[keys[index]] === true)

        setFilterRequest((prev:any) => ({...prev, driverId: { in: resultArray } }))
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.driverId
            return state
        })
        setMode(false)
        handleClose(false)
    }


    const [typingTimer, setTypingTimer] = useState<any>()
    const typingListener = (inputValue: any, typingPause: number, callback: () => void) => {
        clearTimeout(typingTimer); setTypingTimer(setTimeout(callback, typingPause))
    }
    const searchRequest = async (event: React.ChangeEvent<HTMLInputElement>) => {
        typingListener(event.target.value, 800, () => {setSearch(event.target.value)})
    }


    return (
        <Stack sx={{/*padding: '8px 12px', gap: '8px',*/ width: '320px', maxHeight: '340px',
            '*::-webkit-scrollbar': {
                width: '16px',
            },
            '*::-webkit-scrollbar-track': {
                backgroundColor: 'inherit',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#8E8E8E',
                border: '4px solid transparent',
                borderRadius: '6px/6px',
                backgroundClip: 'content-box',
            },
        }}>

            <Box sx={{ 
                position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#FFFFFF', 
                minWidth: '100%', borderBottom: '1px solid #EEEEEE', padding: '12px'
            }}>

            <FormControl
                sx={{
                    //margin: '4px 0px',
                    width: '100%',

                    '& .MuiInputBase-input': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        padding: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                        //maxWidth: '436px',
                        //minWidth: '436px',
                        width: '100%',
                        maxHeight: '36px',
                        minHeight: '36px',
                        padding: '9px 0px 9px 12px',
                        '& input::placeholder': {
                        color: '#8E8E8E',
                        opacity: 1,
                        },
                        '& fieldset': {
                        borderColor: '#D9D9D9',
                        },
                        '& .MuiInputAdornment-root': {
                        pointerEvents: 'none',
                        },

                        '&.MuiOutlinedInput-root:hover': {
                        opacity: 1,
                        '& fieldset': {
                            border: '2px solid #A5E5FD',
                        },
                        },
                        '&.MuiOutlinedInput-root.Mui-focused': {
                        opacity: 1,
                        '& fieldset': {
                            border: '2px solid #009FDA',
                        },
                        },
                    },
                }}
            >
                <OutlinedInput
                placeholder="Поиск"
                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                inputProps={{form: {autoComplete: 'off'}}}
                autoComplete="off"
                onChange={searchRequest}
                />
            </FormControl>

            {/* <Divider sx={{margin: '0px -12px'}}/> */}

            </Box>

            <Box sx={{padding: '0px 12px', height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>

            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    {Object.keys(driverFios).map((key) => (
                        <FormControlLabel key={key}
                        label={key}
                        control={
                            <Checkbox checked={driverFios[key]} onChange={handleChange} name={key} />
                        }
                        sx={formControlLabelStyle}
                    />
                    ))}
                </FormGroup>
            </FormControl>

            </Box>

            <Box sx={{ 
                position: 'sticky', bottom: '0px', zIndex: '99', backgroundColor: '#FFFFFF', 
                minWidth: '100%', borderTop: '1px solid #EEEEEE', padding: '8px 12px'
            }}>

            {/* <Divider sx={{margin: '0px -12px'}}/> */}

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px', position: 'sticky'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>

            </Box>

        </Stack>
    )
}