import { ICertificate } from "../../services/cryptoService";

export interface DrawerProps {
    open: boolean,
    onOpen: React.ReactEventHandler<{}>;
    onClose: React.ReactEventHandler<{}>;
}

export interface ICheckbox {
    id: number,
    name: string,
    declineType?: string,
}

export interface DeclineFormInputData {
    comment: string,
    checkboxes?: ICheckbox[];
}

export interface DefectFormInputData {
    checkboxes?: ICheckbox[];
    comment: string,
}

export enum MedCardStatuses {
    CREATED = "CREATED",
    PROCESSING = 'PROCESSING',
    FINISHED = 'FINISHED',
}
export enum MedCardTypes {
    INNERSHIFT = 'Внутрисменный',
    INNERDAY = 'Внутридневной',
    INNERTRIP = 'Внутрирейсовый',
    POSTSHIFT = 'Послесмменный',
    POSTTRIP = 'Послерейсовый',
    PRESHIFT = 'Предсменный',
    PRETRIP = 'Предрейсовый'
}

export interface INorm {
    id: number,
    code: string,
    name: string,
    value_from: string,
    value_to?: string
}

export interface INormTable {
    systolicPressure?: INorm,
    diastolicPressure?: INorm,
    pulse?: INorm,
    temperature?: INorm,
    alcohol?: INorm
}

export interface IDriver {
    id?: number | null,
    firstname?: string | null,
    lastname?: string | null,
    middlename?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    inn?: string | null,
    snils?: string | null,
    age?: number | null,
    photo?: string | null,
    driver_license_number?: string | null,
    driver_license_issued_at?: string | null,
    employeeId?: number | null,
    company?: string | null,
}

export enum IReportStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    DEFECTED = 'DEFECTED',
    ABORTED = 'ABORTED',
}

export enum NormCodes {
    SYSTOLIC = 'systolic-pressure',
    DIASTOLIC = 'diastolic-pressure',
    PULSE = 'pulse-rate',
    TEMPERATURE = 'temperature',
    ALCOHOL = 'alcohol-content'
}


export interface IDeviation {
    id: number,
    name: string,
    active: boolean
}

export interface IDefect {
    id: number,
    name: string
}


export interface IdEntity {
    id: number,
}


export interface IMedicalReport {
    id?: number,
    deviations?: IdEntity[],
    defects?: IdEntity[],
    comment?: string,
    status?: IReportStatus,
    medicId?: number
    share?: boolean
    archive?: boolean
}

export interface IMedic {
    firstname: string,
    lastname: string,
    middlename: string
}

export interface IMedCard {
    id?: number | null,
    type?: MedCardTypes[] | null,
    driver_health_reports?: IHealthReport[] | [],
    systolic_pressure?: string | null,
    diastolic_pressure?: string | null,
    pulse_rate?: string | null,
    temperature?: string | null,
    alcohol_content?: string | null,
    preassure_measurement_url?: string | null,
    pressure_and_pulse_timecode_from?: number | null,
    pressure_and_pulse_timecode_to?: number | null,
    temperature_measurement_url?: string | null,
    alcohol_and_temperature_from? :number | null,
    alcohol_and_temperature_to? :number | null,
    protocol_and_referral_url?: string,
    referral_url?: string,
    sign_url?:string,
    medic?: IMedic,
    full_url?: string | null,
    sticker_url?: string,
    archive_url?: string,
    archive_url2?: string,
    status?: MedCardStatuses | null,
    driver?: IDriver | null,
    medical_report?: IMedicalReport | null,
    createdAt?: string,
    updatedAt?: string,
    certificate?:ICertificate
    flipping?: boolean
    gmt?: any
}

interface IHealthReport {
    id: number,
    message: string,
}

export interface IMedTableData {
    currentSystolicPressure?: string | null,
    currentDiastolicPressure?: string | null,
    currentPulseRate?: string | null,
    currentTemperature?: string | null,
    currentAlcoholContent?: string | null,
    driverHealthReports?: IHealthReport[] | [],
    fullUrl?: string | null,
    type?: MedCardTypes[] | null,
}


export enum IdentCardStatus {
    WAITING = "WAITING",
    BUSY = "BUSY",
    CONFIRMED = "CONFIRMED",
    UNCONFIRMED = "UNCONFIRMED"
}

export interface IdentDriver {
    id?: number | null,
    birthdate?: string | null,
    age?: number | null,
    gender?: string | null,
    company?: string | null,
    lastname?: string | null,
    firstname?: string | null,
    middlename?: string | null,
    photo?: string | null,
}

export interface IdentCard {
    id?: number | null,
    driver?: IdentDriver | null,
    face?: string | null, 
    license?: string | null,
    video?: string | null,
    medic?: number | null,
    status?: IdentCardStatus | null,
    comment?: string | null,
    medicId?: number | null,
    medical_report?: IMedicalReport | null
    createdAt?: string | null
    flipping?: boolean
}


export enum CardType {
    LOADING = "LOADING",
    MEDICAL = "MEDICAL",
    IDENTIFICATION = "IDENTIFICATION",
    NONE = "NONE",
}