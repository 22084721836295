import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { getDeviationsRequest } from "../../../services/DriverCardService"

const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const formControlLabelStyle = {
    '& .MuiFormControlLabel-label': {
        width: '268px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}

const complaintChipStyle = {
    inlineSize: 'fit-content', maxHeight: '24px',
    backgroundColor: '#009FDA', color: '#FFFFFF',
    fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
    '& .MuiChip-label': { padding: '2px 8px' },
    borderRadius: '4px',
}


interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const ReasonsFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [reasons, setReasons] = useState<any>({})

    useEffect(()=>{(async()=>{
        const response = await getDeviationsRequest()
        console.log(response)
        setReasons(
            response.data
            .map((deviation: any) => deviation.name)
            .reduce((obj: any, item: any) => ({...obj, [item]: false}), {})
        )
    })()},[])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReasons({...reasons, [event.target.name]: event.target.checked})
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(reasons).filter((key) => reasons[key] === true)
        console.log(resultArray)

        setFilterRequest((prev:any) => ({...prev, medical_report: { deviations: { some: { name: { in: resultArray } } } } } ))
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.medical_report
            return state
        })
        setMode(false)
        handleClose(false)
    }


    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <FormControl component="fieldset" variant="standard" sx={{margin: '-5px 0px -5px 0px'}}>
                <FormGroup>
                    {Object.keys(reasons).map((key, index) => (
                        <FormControlLabel key={key}
                            label={
                                <Chip 
                                    label={key}
                                    sx={[complaintChipStyle, key === 'Нет' && { backgroundColor: '#8E8E8E' }]}
                                />
                            }
                            control={
                                <Checkbox 
                                    checked={reasons[key]} onChange={handleChange} name={key}
                                />
                            }
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}