import { useFetcher, useParams } from "react-router-dom";
import { getCardsCountRequest, getCardsRequest } from "../../services/DriverCardService"
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { useEffect, useState } from "react";
import { setReports } from "./ReportsSlice";
import { IMedCard, IReportStatus, MedCardStatuses } from "../Meds/types";
import { excelArgs, getExcelReportTableRequest } from "../../services/fileService";
import { downloadFile } from "./drawers/ReportCard";
import { Chip, Stack } from "@mui/material";
import { ConclusionFilter } from "./components/ConclusionFilter";
import { DateFilter } from "./components/DateFilter";
import { TimeFilter } from "./components/TimeFilter";
import { ComplaintsFilter } from "./components/ComplaintsFilter";
import { PressureFilter } from "./components/PressureFilter";
import { PulseFilter } from "./components/PulseFilter";
import { AlcoholFilter } from "./components/AlcoholFilter";
import { TemperatureFilter } from "./components/TemperatureFilter";
import { InspectTypeFilter } from "./components/InspectTypeFilter";
import { EDSFilter } from "./components/EDSFilter";
import { MessageIcon } from "./assets/MessageIcon";
import { $api } from "../../api";
import sanitizedConfig from "../../Config";
import { MedicFioFilter } from "./components/MedicFioFilter";
import { DriverFioFilter } from "./components/DriverFioFilter";
import { TabelNumberFilter } from "./components/TabelNumberFilter";
import { GenderFilter } from "./components/GenderFilter";
import moment from "moment";
import { DriverBirthdateFilter } from "./components/DriverBirthdateFilter";
import { ReasonsFilter } from "./components/ReasonsFilter";

export interface column {
    id: number,
    name?: string,
    sort?: any | false,
    sortMode?: string,
    filter?: any | false,
    value?: any,
    visible?: boolean,
}

const chipStyle = {
  inlineSize: 'fit-content', maxHeight: '24px',
  backgroundColor: '#009FDA', color: '#FFFFFF',
  fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
  '& .MuiChip-label': { padding: '2px 8px' },
  borderRadius: '4px',
}

export const getStatusChip = (status: IReportStatus, share?: boolean) => {

    const chipStyle = {
      '&.MuiChip-root': { 
        pointerEvents: 'none',
        maxHeight: '22px', border: '1px solid', borderRadius: '4px',
        fontFamily: 'Roboto', fintWeight: '400', fontSize: '14px', lineHeight: '18px',
        inlineSize: 'fit-content',
      },
      '& .MuiChip-label': { 
        padding: '2px 8px',
        marginTop: 'auto' 
      }
    }
  
    return (
      <Stack gap={'4px'}>
        {(()=>{
          switch (status) {
            case IReportStatus.ACCEPTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={'Допущен'} />
            case IReportStatus.DEFECTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={'Брак'} />
            case IReportStatus.REJECTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={'Не допущен'} />
            case IReportStatus.ABORTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#EAF8FF', borderColor: '#009FDA', color: '#009FDA' }} variant="outlined" label={'Прерван'} />
            default:
              return <></> //<Chip sx={{ borderRadius: '4px' }} color="default" variant="outlined" label={status} />
          }
        })()}
        { share ? 
          <Chip sx={{ ...chipStyle, backgroundColor: '#EAF8FF', borderColor: '#009FDA', color: '#009FDA' }} variant="outlined" label={<MessageIcon />} /> 
        : <></>}
      </Stack>
    )
  }
  
  export const getDriverHealthReportChip = (row: IMedCard) => {
    return (
      <Stack gap={'4px'}>
      {!!row.driver_health_reports
      ? row.driver_health_reports.length > 0
        ? row.driver_health_reports?.map(
          (elem: any) => {
            return <Chip 
              sx={{ 
                inlineSize: 'fit-content', maxHeight: '24px',
                backgroundColor: '#009FDA', color: '#FFFFFF',
                fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
                '& .MuiChip-label': { padding: '2px 8px' },
                borderRadius: '4px',
              }}
              label={elem.message}
            ></Chip>
          })
            : <Chip
                sx={{ 
                  inlineSize: 'fit-content', maxHeight: '24px',
                  backgroundColor: '#8E8E8E', color: '#FFFFFF',
                  fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
                  '& .MuiChip-label': { padding: '2px 8px' },
                  borderRadius: '4px',
                }}
                label={'Нет'}
              ></Chip>
          : <></>
        }
    </Stack>
    )
  }

export const useReportsPageModel = () => {

    const dispatch = useAppDispatch();
    const { medic } = useAppSelector(state => state.mainReducer)
    const { reports } = useAppSelector(state => state.reportsReducer)
    const { id } = medic
    const { curPage } = useParams();
    const [page, setPage] = useState<number>(1)
    const [pageQty, setPageQty] = useState(0);
    const [perPage, setPerPage] = useState<number>(50)

    const [filterRequest, setFilterRequest] = useState<Object>({createdAt: { 
      gte: moment.tz('Europe/Moscow').startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
      lte: moment.tz('Europe/Moscow').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    }})
    const [sortingRequest, setSortingRequest] = useState<Object>({orderBy: {id: 'desc'}})

    const [statisticsRequest, setStatisticsRequest] = useState<Object>({createdAt: { 
      gte: moment.tz('Europe/Moscow').startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
      lte: moment.tz('Europe/Moscow').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    }})
    const [reportsStatistics, setReportsStatistics] = useState<any>({general: 0, accepted: 0, rejected: 0})

    const [tabValue, setTabValue] = useState<string>('MED_LOG')


    const getMedReports = async (query: any) => {
      try {
        const response = await getCardsRequest(query)
        console.log('MEDREPORTS')
        console.log(response)
        return response
      } catch (e) {
        console.log(e)
      }
    }

    const getMedReportsCount = async (query:any) => {
      try {
        const accepted = (await getCardsCountRequest({medical_report: {status: {in: [IReportStatus.ACCEPTED]}}, ...query})).data
        const rejected = (await getCardsCountRequest({medical_report: {status: {in: [IReportStatus.REJECTED, IReportStatus.DEFECTED]}}, ...query})).data
        const general = accepted + rejected
        return {general: general, accepted: accepted, rejected: rejected}
      } catch (e) {
        console.log(e)
      }
    }

  
    useEffect(() => {
        console.log('curPage')
        console.log(curPage)
        console.log(Number(curPage))
        if (!!curPage) {
            setPage(Number(curPage))
         } else setPage(1);
    }, [curPage])

    useEffect(() => {
        console.log('page')
        console.log(page)
        if (page && perPage) {
          const resp = getMedReports({page: page, per_page: perPage, ...filterRequest, ...sortingRequest})
          resp.then((result) => {
            console.log('zalupa')
            console.log(result)
            dispatch(setReports(result?.data.data))
            setPageQty(result?.data.last_page)  
        })
          getMedReportsCount(statisticsRequest).then((response)=>setReportsStatistics(response))
        }
    }, [page, perPage, filterRequest, sortingRequest])

    const changePage=(num:any)=>{
        setPage(num)
    }

    

    const setSortMode = (id: number, mode: string) => {
      setColumns((prev) => prev.map((col) => col.id === id ? {...col, sortMode: mode} : {...col, sortMode: ''} ))
    }

    const [openViewSettings, setOpenViewSettings] = useState<boolean>(false)

    const [columns, setColumns] = useState<column[]>([
        { 
          id: 1,
          name: '№', 
          sort: (cc: number) => {setSortingRequest({orderBy: { id: cc%2===0 ? 'desc' : 'asc' } }); setSortMode(1, cc%2===0 ? 'desc' : 'asc')},
          sortMode: '',
          filter: false,
          value: (row: IMedCard) => (row.id),
          visible: true,
        },
        { 
          id: 2,
          name: 'Заключение', 
          sort: (cc: number) => {setSortingRequest({orderBy: { medical_report: { status: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(2, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<ConclusionFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.medical_report?.status ? getStatusChip(row.medical_report?.status, row.medical_report.share) : <></>),
          visible: true,
        },
        { 
          id: 3,
          name: 'Дата', 
          sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(3, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<DateFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode} setStatisticsRequest={setStatisticsRequest}/>), 
          value: (row: IMedCard) => (row?.createdAt ? row.createdAt.substring(0,10).split('-').reverse().join('.') : '-'),
          visible: true,
        },
        { 
          id: 4,
          name: 'Время', 
          sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(4, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<TimeFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode} setStatisticsRequest={setStatisticsRequest}/>), 
          value: (row: IMedCard) => (row.createdAt?.substring(11)),
          visible: true,
        },
        { 
          id: 5,
          name: 'GMT', 
          sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(5, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          value: (row: IMedCard) => (row?.gmt || '-'),
          visible: true,
        },
        { 
          id: 6,
          name: 'Наименование организации', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver: { company: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(6, cc%2===0 ? 'asc' : 'desc')},
          filter: false,
          value: (row: IMedCard) => (row.driver?.company),
          visible: true,
        },
        { 
          id: 7,
          name: 'ФИО', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver: { lastname: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(7, cc%2===0 ? 'asc' : 'desc')},
          filter: (handleClose: any, setMode: any) => (<DriverFioFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (
            <span>
              {!!row.driver?.lastname ? `${row.driver?.lastname} ` : <></>}
              {!!row.driver?.firstname ? `${row.driver?.firstname} ` : <></>}
              {!!row.driver?.middlename ? `${row.driver?.middlename} ` : <></>}
            </span>
            ),
          visible: true,
        },
        { 
          id: 8,
          name: 'ФИО медработника', 
          sort: (cc: number) => {setSortingRequest({orderBy: { medic: { lastname: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(8, cc%2===0 ? 'asc' : 'desc')},
          filter: (handleClose: any, setMode: any) => (<MedicFioFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (
            <span>
              {!!row.medic?.lastname ? `${row.medic?.lastname} ` : <></>}
              {!!row.medic?.firstname ? `${row.medic?.firstname} ` : <></>}
              {!!row.medic?.middlename ? `${row.medic?.middlename} ` : <></>}
            </span>
          ),
          visible: true,
        },
        { 
          id: 9,
          name: '№ п/п', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driverId: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(9, cc%2===0 ? 'asc' : 'desc')},
          filter: false,
          value: (row: IMedCard) => (row.driver?.id),
          visible: true,
        },
        { 
          id: 10,
          name: 'Пол', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver: { gender: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(10, cc%2===0 ? 'asc' : 'desc')},
          filter: (handleClose: any, setMode: any) => (<GenderFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (row.driver?.gender),
          visible: true,
        },
        { 
          id: 11,
          name: 'Дата рождения', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver: { birthdate: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(11, cc%2===0 ? 'asc' : 'desc')},
          filter: (handleClose: any, setMode: any) => (<DriverBirthdateFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.driver?.birthdate ? row.driver?.birthdate.substring(0,10).split('-').reverse().join('.') : '-'),
          visible: true,
        },
        { 
          id: 12,
          name: 'Таб. номер', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driverId: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(12, cc%2===0 ? 'asc' : 'desc')},
          filter: (handleClose: any, setMode: any) => (<TabelNumberFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (row.driver?.id),
          visible: true,
        },
        { 
          id: 13,
          name: 'Жалобы', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver_health_reports: { _count: cc%2===0 ? 'asc' : 'desc'} } }); setSortMode(13, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<ComplaintsFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (getDriverHealthReportChip(row)),
          visible: true,
        },
        { 
          id: 14,
          name: 'Артериальное давление', 
          sort: (cc: number) => {setSortingRequest({orderBy: cc%2===0 ? { diastolic_pressure: 'asc' } : { systolic_pressure: 'desc' } }); setSortMode(14, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<PressureFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.systolic_pressure + '/' + row.diastolic_pressure),
          visible: true,
        },
        { 
          id: 15,
          name: 'Пульс', 
          sort: (cc: number) => {setSortingRequest({orderBy: { pulse_rate: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(15, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<PulseFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.pulse_rate),
          visible: true,
        },
        { 
          id: 16,
          name: 'Содержание алкоголя', 
          sort: (cc: number) => {setSortingRequest({orderBy: { alcohol_content: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(16, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<AlcoholFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.alcohol_content),
          visible: true,
        },
        { 
          id: 17,
          name: 'Температура', 
          sort: (cc: number) => {setSortingRequest({orderBy: { temperature: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(17, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<TemperatureFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),  
          value: (row: IMedCard) => (row.temperature),
          visible: true,
        },
        { 
          id: 18,
          name: 'Вид осмотра', 
          sort: (cc: number) => {setSortingRequest({orderBy: { type: { name: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(18, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<InspectTypeFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.type),
          visible: true,
        },
        { 
          id: 19,
          name: 'Причины недопуска', 
          sort: (cc: number) => {setSortingRequest({orderBy: { medical_report: { deviations: { _count: cc%2===0 ? 'asc' : 'desc' } } } }); setSortMode(19, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<ReasonsFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (!!row.medical_report?.deviations ? <Stack gap={'4px'}>{row.medical_report?.deviations.map((item:any)=><Chip sx={chipStyle} label={item.name}/>)}</Stack> : ''),
          visible: true,
        },
        { 
          id: 20,
          name: 'ЭЦП медработника', 
          sort: (cc: number) => {setSortingRequest({orderBy: { certificate: { value: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(20, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<EDSFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (!!row.certificate ? row.certificate.value : ''),
          visible: true,
        },
    ])


    return {
        reports,
        page,
        pageQty,
        changePage,

        openViewSettings,
        setOpenViewSettings,
        columns,
        setColumns,
        setFilterRequest,

        filterRequest,
        sortingRequest,

        reportsStatistics,

        tabValue,
        setTabValue,

        perPage,
        setPerPage,
    }
}