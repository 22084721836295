import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack, Typography } from "@mui/material"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"


export const ThermometerIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3.125C9.50272 3.125 9.02581 3.32254 8.67418 3.67417C8.32255 4.02581 8.125 4.50272 8.125 5V11.2344L7.91688 11.4206C7.44509 11.8425 7.11254 12.3977 6.96322 13.0128C6.8139 13.6278 6.85485 14.2737 7.08066 14.865C7.30648 15.4562 7.7065 15.965 8.22779 16.3239C8.74909 16.6829 9.36709 16.875 10 16.875C10.6329 16.875 11.2509 16.6829 11.7722 16.3239C12.2935 15.965 12.6935 15.4562 12.9193 14.865C13.1452 14.2737 13.1861 13.6278 13.0368 13.0128C12.8875 12.3977 12.5549 11.8425 12.0831 11.4206L11.875 11.2344V5C11.875 4.50272 11.6775 4.02581 11.3258 3.67417C10.9742 3.32254 10.4973 3.125 10 3.125ZM6.875 5C6.875 4.1712 7.20424 3.37634 7.79029 2.79029C8.37634 2.20424 9.1712 1.875 10 1.875C10.8288 1.875 11.6237 2.20424 12.2097 2.79029C12.7958 3.37634 13.125 4.1712 13.125 5V10.6881C13.7279 11.3035 14.1357 12.0831 14.2973 12.9293C14.4589 13.7755 14.3671 14.6506 14.0334 15.4448C13.6996 16.2391 13.1389 16.9171 12.4213 17.3939C11.7038 17.8706 10.8615 18.125 10 18.125C9.13851 18.125 8.29619 17.8706 7.57867 17.3939C6.86115 16.9171 6.30036 16.2391 5.96662 15.4448C5.63289 14.6506 5.54106 13.7755 5.70266 12.9293C5.86426 12.0831 6.27208 11.3035 6.875 10.6881V5ZM9.375 7.5C9.375 7.33424 9.44085 7.17527 9.55806 7.05806C9.67527 6.94085 9.83424 6.875 10 6.875C10.1658 6.875 10.3247 6.94085 10.4419 7.05806C10.5592 7.17527 10.625 7.33424 10.625 7.5V11.9812C11.0422 12.1287 11.3938 12.4189 11.6177 12.8005C11.8416 13.1821 11.9234 13.6306 11.8486 14.0667C11.7738 14.5028 11.5473 14.8985 11.209 15.1837C10.8707 15.4689 10.4425 15.6253 10 15.6253C9.55753 15.6253 9.12931 15.4689 8.79102 15.1837C8.45273 14.8985 8.22617 14.5028 8.15137 14.0667C8.07657 13.6306 8.15836 13.1821 8.38228 12.8005C8.60619 12.4189 8.95782 12.1287 9.375 11.9812V7.5Z" fill="#787878"/>
        </svg>
    )
}


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px 8px 0px -4px',
        pointerEvents: 'none',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const TemperatureFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [temperatureFrom, setTemperatureFrom] = useState<string>('')
    const [temperatureTo, setTemperatureTo] = useState<string>('')

    const [temperature, setTemperature] = useState<any>({ 
        'До 34 градусов': false, 'От 34 до 36 градусов': false, '36.6 градусов': false, 'От 37 до 39 градусов': false, 'От 40 градусов': false 
    })

    const temperatureValues: any = {
        'До 34 градусов': {gte: '0.0', lte: '34.0'}, 
        'От 34 до 36 градусов': {gte: '34.0', lte: '36.0'}, 
        '36.6 градусов': {gte: '36.6', lte: '36.6'}, 
        'От 37 до 39 градусов': {gte: '37.0', lte: '39.0'}, 
        'От 40 градусов': {gte: '40.0', lte: '99.9'}
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemperature(Object.entries(temperature).forEach(([key]) => {temperature[key] = false}))
        setTemperature({...temperature, [event.target.name]: event.target.checked})

        setTemperatureFrom(temperatureValues[event.target.name].gte)
        setTemperatureTo(temperatureValues[event.target.name].lte)  
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(temperature).filter((key) => temperature[key] === true)
        console.log(resultArray)

        if (temperatureFrom || temperatureTo) {
            setFilterRequest((prev:any) => ({...prev, temperature: { 
                gte: temperatureFrom ? temperatureFrom : '0.0', 
                lte: temperatureTo ? temperatureTo :  '99.9'
            }}))
        }
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.temperature
            return state
        })
        setMode(false)
        handleClose(false)
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px', margin: '4px 0px'}}>
                <DemoItem label={<Typography sx={labelStyle}>С, градусов</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><ThermometerIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '00.0',
                            }}
                            value={temperatureFrom}
                            onChange={(e) => setTemperatureFrom((prev)=>{
                                if (e.target.value[0] !== '0') {
                                    if (!e.target.value.match(/^(?!\.)(\d?\d?)(\.?)(\d?)$/)) return prev
                                    if (e.target.value[1] === '.') return prev
                                    if (e.target.value.length === 3 && e.target.value[2] !== '.') return [e.target.value.slice(0, 2), '.', e.target.value.slice(2)].join('')
                                    if (e.target.value.length === 3) return e.target.value.slice(0, -1); 
                                    return e.target.value 
                                } else {
                                    if (!e.target.value.match(/^(?!\.)(\d?)(\.?)(\d?)$/)) return prev
                                    if (e.target.value.length === 2 && e.target.value[1] !== '.') return [e.target.value.slice(0, 1), '.', e.target.value.slice(1)].join('')
                                    if (e.target.value.length === 2) return e.target.value.slice(0, -1); 
                                    return e.target.value 
                                }
                            })}
                        />
                    </FormControl>
                </DemoItem>

                <DemoItem label={<Typography sx={labelStyle}>По, градусов</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><ThermometerIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '00.0',
                            }}
                            value={temperatureTo}
                            onChange={(e) => setTemperatureTo((prev)=>{
                                if (e.target.value[0] !== '0') {
                                    if (!e.target.value.match(/^(?!\.)(\d?\d?)(\.?)(\d?)$/)) return prev
                                    if (e.target.value[1] === '.') return prev
                                    if (e.target.value.length === 3 && e.target.value[2] !== '.') return [e.target.value.slice(0, 2), '.', e.target.value.slice(2)].join('')
                                    if (e.target.value.length === 3) return e.target.value.slice(0, -1); 
                                    return e.target.value 
                                } else {
                                    if (!e.target.value.match(/^(?!\.)(\d?)(\.?)(\d?)$/)) return prev
                                    if (e.target.value.length === 2 && e.target.value[1] !== '.') return [e.target.value.slice(0, 1), '.', e.target.value.slice(1)].join('')
                                    if (e.target.value.length === 2) return e.target.value.slice(0, -1); 
                                    return e.target.value 
                                }
                            })}
                        />
                    </FormControl>
                </DemoItem>
            </Box>

            <Divider sx={{margin: '0px -12px'}}/>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(temperature).map((key, index) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={temperature[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                temperature[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}