

import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux";
import { setBase64File, setCardTimeProgress, setDefects, setDeviations, setIdentCardInfo, setMedCardInfo, setMedicalReport, setNorms } from "./MedExamsSlice";
import { getCardsRequest, getDefectsRequest, getDeviationsRequest, getDocumentsRequest, getNormsRequest, updateCardRequest, updateDocumentRequest } from '../../services/DriverCardService';
import { CardType, IDeviation, IDriver, IMedCard, IMedTableData, IMedicalReport, INorm, INormTable, IReportStatus, IdentCard, IdentCardStatus, MedCardStatuses, NormCodes } from "./types";
import { setIsActive } from "../../slices/CommonInfoSlice";
import { ICertificate, createSign, getUserCertificate } from "../../services/cryptoService";
import { createFileFromBase64, createFileRequest, getBase64FileRequest, getBufferFileRequest } from "../../services/fileService";
import { getUserCertificates } from "crypto-pro";
import { useModal } from "../../modules/layout/components/modals/useModal";
import { ModalNames } from "../../slices/AppSlice";
import { Buffer } from "buffer";
import { $file } from "../../api";
// import { writeFileSync } from "data:fs";




export const getTableDataFromCard = (card: IMedCard | null) => {

    if (card !== null) {
        const {
            systolic_pressure,
            diastolic_pressure,
            pulse_rate,
            temperature,
            alcohol_content,
            full_url,
            type,
            driver_health_reports
        } = card
        const data: IMedTableData = {
            currentSystolicPressure: systolic_pressure,
            currentDiastolicPressure: diastolic_pressure,
            currentPulseRate: pulse_rate,
            currentTemperature: temperature,
            currentAlcoholContent: alcohol_content,
            fullUrl: full_url,
            type: type,
            driverHealthReports: driver_health_reports
        }
        return data
    } else return null
}


export const updateCard = async (data: IMedCard) => {
    try {
        const response = await updateCardRequest(data);
        console.log('updated response')
        console.log(response)
        const updatedData = response.data;
        return updatedData;
    } catch (e) {

    }

}




export const setConclusion = async (card: IMedCard | null, medicalReport: IMedicalReport, id: number, certificate:ICertificate) => {


    // const certificate = await getUserCertificate();

    try {
        const updatedData = await updateCard({
            id: !!card ? card.id : null,
            type: !!card ? card.type : null,
            medical_report: medicalReport,
            status: MedCardStatuses.PROCESSING,
            certificate: certificate,

        })
        console.log('updated')
        console.log(updatedData)

        let url = updatedData.protocol_and_referral_url

        // const blobFile = $file.get(url)
        // async (result:any) => {
        console.log('updatedData')
        console.log(updatedData)
        const fileName = url.split('/').reverse()[0]
        console.log('first url')
        console.log(url)
        const bufferFileResponse = await getBufferFileRequest(fileName)

        console.log('bufferFileResponse')
        console.log(bufferFileResponse)
        if (!!bufferFileResponse) {
            const bufferFile = Buffer.from(bufferFileResponse.data.Body.data)
            console.log('bufferFile')
            console.log(bufferFile)
            console.log(bufferFile.buffer)
            console.log('certificateValue')
            console.log(certificate.value)
            if (!!bufferFile) {
                const signature = await createSign(certificate.value, bufferFile)
                console.log('signature')
                console.log(signature)
                if (!!signature) {

                    const bufferSignature = new Blob([Buffer.from(signature)])
                    const formData = new FormData()
                    formData.append('file', bufferSignature, 'sign.sgn')

                    const signUrl = !!signature ? await createFileRequest(formData) : undefined
                    if (!!card && !!signUrl && !!signature) {

                        const response = await updateCard({ id: card.id, type: card.type, sign_url: signUrl.data.link, status: MedCardStatuses.FINISHED })
                        console.log('signed card')
                        console.log(response)

                        return response


                    }
                } else throw new Error('Подпись не сфромировалась')
            } else throw new Error('не удалось получить документ для подписания')
        } else throw new Error('не удалось получить документ для подписания')

        // })
    } catch (e) {
        if (!!card) {
            const response = await updateCard({ id: card.id, type: card.type, status: MedCardStatuses.FINISHED, medical_report: { status: IReportStatus.DEFECTED, comment: 'Техническая ошибка' } })
            // console.log('second url')
            // console.log(response.data.protocol_and_referral_url)

        }
        console.log(e)
        // window.alert('Ошибка')
        return null
    }
}




export const useMedsPageModel = () => {
    const [isDefectOpen, setDefectOpen] = useState(false);
    const [isDeclineOpen, setDeclineOpen] = useState(false);
    const [isDeclineIdentOpen, setDeclineIdentOpen] = useState(false);
    const dispatch = useAppDispatch();
    const { card, norms, identCard, base64FileString } = useAppSelector(state => state.examReducer)
    const { medic, isActive } = useAppSelector(state => state.mainReducer)

    const [driverData, setDriverData] = useState<IDriver | null | undefined>(null)


    const { addModal, removeModal, changeCurrentModal } = useModal()



    const [isRequest, setRequest] = useState<boolean>(false)
    const [isAxiosError, setError] = useState<boolean>(false)
    const [isAxiosComplete, setComplete] = useState<boolean>(false)

    //const [isSubscribed, setSubscribed] = useState<boolean>(false)
    const [cardType, setCardType] = useState<CardType>(CardType.LOADING)

    useEffect(() => {
        if (card === null && identCard === null) return;
        const resetCards = async () => {
            if (card !== null) {
                updateCard({ id: card.id, type: card.type, status: MedCardStatuses.CREATED });
            }
            if (identCard !== null) {
                updateDriverDocument({ id: identCard.id, status: IdentCardStatus.WAITING });
            }
        }
        window.addEventListener('beforeunload', resetCards)
        return () => {
            window.removeEventListener('beforeunload', resetCards)
        }
    }, [card, identCard, cardType])


    const subscribeOnMedCard = async (medCard: any) => {
        setCardType(CardType.LOADING)

        const updatedCard = await updateCard({ id: medCard.id, type: medCard.type, status: MedCardStatuses.PROCESSING });
        console.log('updatedCard')
        console.log(updatedCard)
        dispatch(setMedCardInfo(updatedCard))
        dispatch(setCardTimeProgress(0))

        //setSubscribed((prevState)=>prevState=false)
        setCardType(CardType.MEDICAL)
    }

    const subscribeOnIdentCard = async (identCard: any) => {
        setCardType(CardType.LOADING)

        const updatedCard = await updateDriverDocument({ id: identCard.id, status: IdentCardStatus.BUSY });
        console.log(updatedCard)
        dispatch(setIdentCardInfo(updatedCard))
        dispatch(setCardTimeProgress(0))

        //setSubscribed((prevState)=>prevState=false)
        setCardType(CardType.IDENTIFICATION)
    }

    const isActiveRef = useRef(isActive)
    isActiveRef.current = isActive
    const timerIdRef = useRef()
    useEffect(()=>{return () => {clearInterval(timerIdRef.current)} },[])
    // Либо писать интервалы в useEffect'ах, либо обновлять значение через useRef и очищать интервал при размонтировании компонента useEffect return.

    const subscribeOnCards = async () => {
        let medCard: any
        let identCard: any
        let timerId: any
        timerId = setInterval(async () => {
            timerIdRef.current = timerId
            if (!isActiveRef.current) {
                clearInterval(timerId)
            } else {
                //setSubscribed((prevState)=>prevState=true)

                medCard = await getCard()
                identCard = await getDriversDocuments()

                console.log(medCard)
                console.log(identCard)

                // subscribeOnIdentCard(identCard)
                // dispatch(setMedCardInfo(null))
                // clearInterval(timerId) 

                if (medCard & identCard) {
                    const medCardDate = new Date(medCard.createdAt).getTime()
                    const identCardDate = new Date(identCard.createdAt).getTime()
                    if (medCardDate < identCardDate) {
                        subscribeOnMedCard(medCard)
                    } else {
                        subscribeOnIdentCard(identCard)
                    }
                    clearInterval(timerId)
                } else if (medCard) {
                    subscribeOnMedCard(medCard)
                    clearInterval(timerId)
                } else if (identCard) {
                    subscribeOnIdentCard(identCard)
                    clearInterval(timerId)
                } else {
                    setCardType(CardType.NONE)
                }
            }
            console.log(cardType)
        }, 2000);
    }


    const resetCardStatus = async () => {
        if (!isActive) {
            if (card !== null) {
                const updatedCard = await updateCard({ id: card.id, type: card.type, status: MedCardStatuses.CREATED });
                dispatch(setMedCardInfo(null))
                dispatch(setCardTimeProgress(0))
                setDriverData(null)
                dispatch(setDeviations(null))
                //setSubscribed((prevState)=>prevState=false)
                setCardType(CardType.LOADING)
            }
            if (identCard !== null) {
                const updatedCard = await updateDriverDocument({ id: identCard.id, status: IdentCardStatus.WAITING });
                dispatch(setIdentCardInfo(null))
                dispatch(setCardTimeProgress(0))
                setDriverData(null)

                //setSubscribed((prevState)=>prevState=false)
                setCardType(CardType.LOADING)

            }
        }
    }


    const setErrorModal = (state: boolean) => {
        setError((prevState) => prevState = state)
    }
    const setOnRequestModal = (state: boolean) => {
        setRequest((prevState) => prevState = state)
    }
    const setCompleteModal = (state: boolean) => {
        setComplete((prevState) => prevState = state)
    }
    // const setSubscribedModal=(state:boolean)=>{
    //     setSubscribed((prevState)=>prevState=state)
    // }

    const getCard = async () => {
        let responsedData;
        try {
            const response = await getCardsRequest({
                status: {in:[MedCardStatuses.CREATED]},
                 per_page: 1,
                //   id:'821',
                    orderBy: {createdAt: 'desc'}})
            console.log('CARD RESPONSE')
            console.log(response)
            responsedData = response.data.data[0];
            console.log('МЕДОСМОТР')
            console.log(response.data.data[0])
            return responsedData;
        } catch (e) {

        }
    }
    const updateCard = async (data: IMedCard) => {
        try {
            const response = await updateCardRequest(data);
            console.log('updated response')
            console.log(response)
            const updatedData = response.data;
            return updatedData;
        } catch (e) {

        }

    }



    
    const getDriversDocuments = async () => {
        try {
            const response = await getDocumentsRequest(IdentCardStatus.WAITING, undefined, 1);
            // const rsp = await getDocumentsRequest();
            // console.log(rsp)
            console.log(response)
            const responsedData = response.data.data[0];
            console.log('ИДЕНТИФИКАЦИЯ')
            console.log(response.data.data[0])
            return responsedData;
        } catch (e) {
            console.log(e)
        }
    }

    const updateDriverDocument = async (data: IdentCard) => {
        try {
            const response = await updateDocumentRequest(data)
            console.log('updated response')
            console.log(response)
            const updatedData = response.data;
            return updatedData;
        } catch (e) {
            console.log(e)
        }
    }




    const approve = async () => {
        let medicalReport:IMedicalReport | null = null;

        if(!!medic){
            if(!!medic.id){
                medicalReport = {
                    status: IReportStatus.ACCEPTED,
                    medicId:medic.id,
                }
                dispatch(setMedicalReport(medicalReport))
                changeCurrentModal(ModalNames.CERTIFICATES)
                dispatch(setBase64File(undefined))
            
            }
           
        }
          



    }

    //TODO: подтвердить идентификацию.
    const approveIdent = () => {
        //setOnRequestModal(true)
        try {
            updateDriverDocument({
                id: identCard !== null ? identCard.id : null,
                medicId: medic?.id,
                status: IdentCardStatus.CONFIRMED
            })
            setRequest((prevState) => prevState = false)
            //setCompleteModal(true)
            dispatch(setIdentCardInfo(null))
            changeCurrentModal(ModalNames.SUCCESSIDENT)
            //window.alert('Водитель допущен')
        } catch (e) {
            //setOnRequestModal(true)
            //setErrorModal(true)
            //window.alert('Ошибка')
        }
    }



    const getNorms = async () => {
        try {
            const response = await getNormsRequest();
            console.log('norms')
            console.log(response)
            dispatch(setNorms(response.data))
        } catch (e) {

        }
    }



    useEffect(() => {
        getNorms();
    }, [])


    // const getDeviations = async () => {
    //     try {
    //         const response = await getDeviationsRequest();
    //         console.log('DEVIATIONS')
    //         console.log(response)
    //         dispatch(setDeviations(response.data));
    //     } catch (e) {

    //     }
    // }

    // const getDefects = async () => {
    //     try {
    //         const response = await getDefectsRequest();
    //         console.log('DEFECTS')
    //         console.log(response)
    //         dispatch(setDefects(response.data));
    //     } catch (e) {

    //     }


    // }



    useEffect(() => {
        console.log('redux cards')
        console.log(card)
        if (isActive && (card === null && identCard === null)) {

            subscribeOnCards()
        } else {
            setDriverData(card?.driver)
            resetCardStatus()
        }
    }, [card, identCard, isActive])



    const toggleDefectDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setDefectOpen(open);
    };

    const toggleDeclineDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setDeclineOpen(open);
    };

    const toggleDeclineIdentDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setDeclineIdentOpen(open);
    };





    const normTableData: INormTable | null = !!norms && norms !== null ? {
        systolicPressure: norms.find((element: INorm) => {
            return element.code === NormCodes.SYSTOLIC
        }),
        diastolicPressure: norms.find((element: any) => {
            return element.code === NormCodes.DIASTOLIC
        }),
        pulse: norms.find((element: any) => {
            return element.code === NormCodes.PULSE
        }),
        temperature: norms.find((element: any) => {
            return element.code === NormCodes.TEMPERATURE
        }),
        alcohol: norms.find((element: any) => {
            return element.code === NormCodes.ALCOHOL
        }),
    } : null



    return {
        isDefectOpen,
        isDeclineOpen,
        isDeclineIdentOpen,
        toggleDefectDrawer,
        toggleDeclineDrawer,
        toggleDeclineIdentDrawer,
        card,
        identCard,
        driverData,
        tableMedData: getTableDataFromCard(card),
        approve,
        approveIdent,
        normTableData,

        cardType,
        isActive,

    }
}