
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useState } from "react";
import { activeColor, drawerBoxStyle, optionStyle } from "../../../styles/styles";
import { DrawerProps } from "../../../types";

import { useForm } from "react-hook-form";

import { useDeclineDrawerFormModel } from "./DeclineDrawerFormModel";
import { buttonStyle } from "../../../../Login/login-styles";
import { ReactComponent as CloseIcon } from '../assets/Close.svg'
import { FormCheckboxes } from "../../FormCheckboxes";
import { FormInputText } from "../../FormInputText";
import { MedFormCheckboxes } from "../../../MedFormCheckboxes";
import { MedFormInputText } from "../../../MedFormInputText";


export const titleMargin = {
    marginLeft: '10px'
}

export const DeclineDrawerForm = (props: DrawerProps) => {

    const model = useDeclineDrawerFormModel(props.onClose);

    const isOtherFieldPicked = () => {
        const values = model.getValues()
        return !!values.checkboxes.find((elem: any) => elem.id === 11)
    }

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            sx={{
                width: '362px',
                // backgroundColor:'#EEEEEE',
                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            {/* <Box
                sx={drawerBoxStyle}
                role="presentation"
                //padding={3}
            > */}
                <Box sx={{position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px'}}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', /*marginBottom: '17px'*/ }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>Отклонение показателей</Typography>
                    <IconButton onClick={props.onClose} color="secondary" aria-label="add an alarm"
                        sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                </Box>
                <Stack sx={{ ...drawerBoxStyle, padding: '0px 24px', height: '100%', overflowY: 'auto', gap: '16px' }}>

                    <MedFormCheckboxes
                        control={model.control}
                        setValue={model.handleFormValues}
                        name={"checkboxes"}
                        checkboxValues={model.options}
                        getValues={model.getValues}
                    />
                    <Box>
                        <FormInputText
                            //rules={{ required: model.isTextFieldRequired}}
                            name={'comment'}
                            control={model.control}
                            label={'Комментарий'}
                            //isDisabled = {model.disableSubmit}
                            isRequired={model.isTextFieldRequired}
                        />
                        <span style={{
                            color: 'red',
                        }}>
                            {/* {isOtherFieldPicked() && !model.getValues('comment').trim()  ? 'Обязательно укажите причину' : ''} */}
                            {model.isTextFieldRequired ? 'Обязательно укажите причину' : ''}
                        </span>
                    </Box>
                    <Box>

                        <FormControlLabel control={<Checkbox onChange={()=>{model.setSMedic((prev)=>!prev)}}/>} label="Отправить старшему медику" />

                    </Box>

                </Stack>
                <Box sx={{position: 'sticky', bottom: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px'}}>

                    <Button
                    
                   //disabled={model.getValues().checkboxes.length==0} 
                   disabled={model.disableSubmit}
                    sx={{ ...buttonStyle,  backgroundColor: activeColor, /*marginTop: 2*/ 
                        '&:hover': {
                            backgroundColor: '#007DB8',
                            boxShadow: 'none',
                        }
                    }}
                        onClick={model.submitHandler}
                        fullWidth
                        variant="contained">
                        Завершить осмотр
                    </Button>

                </Box>


            {/* </Box> */}
        </SwipeableDrawer>
    )
}

