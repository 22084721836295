import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack, Typography } from "@mui/material"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"
import { Preview } from "react-dropzone-uploader"


export const SystolicPressureIcon = () => {
    return (
        <Typography sx={{fontFamily: 'Roboto', fontWeight: '400', fontSize: '14px', lineHeight: '1.3', color: '#3E3E3E'}}>SYS</Typography>
    )
}

export const DiastolicPressureIcon = () => {
    return (
        <Typography sx={{fontFamily: 'Roboto', fontWeight: '400', fontSize: '14px', lineHeight: '1.3', color: '#3E3E3E'}}>DIA</Typography>
    )
}


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px 12px -2px -4px',
        pointerEvents: 'none',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}


interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const PressureFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [sysFrom, setSysFrom] = useState<string>('')
    const [sysTo, setSysTo] = useState<string>('')
    const [diaFrom, setDiaFrom] = useState<string>('')
    const [diaTo, setDiaTo] = useState<string>('')

    const [pressure, setPressure] = useState<any>({ 'Повышенное': false, 'Нормальное': false, 'Пониженное': false });

    const pressureValues: any = { 
        'Повышенное': {
            sys: {gte: '120', lte: '999'},
            dia: {gte: '80', lte: '99'}
        },
        'Нормальное': {
            sys: {gte: '90', lte: '120'},
            dia: {gte: '60', lte: '80'}
        },
        'Пониженное': {
            sys: {gte: '0', lte: '90'},
            dia: {gte: '0', lte: '60'}
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPressure(Object.entries(pressure).forEach(([key]) => {pressure[key] = false}))
        setPressure({...pressure, [event.target.name]: event.target.checked})

        setSysFrom(pressureValues[event.target.name].sys.gte)
        setSysTo(pressureValues[event.target.name].sys.lte)
        setDiaFrom(pressureValues[event.target.name].dia.gte)
        setDiaTo(pressureValues[event.target.name].dia.lte)
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(pressure).filter((key) => pressure[key] === true)
        console.log(resultArray)

        if (sysFrom || sysTo || diaFrom || diaTo) {
            setFilterRequest((prev:any) => ({...prev, 
                systolic_pressure: { 
                    gte: sysFrom ? sysFrom : '0', 
                    lte: sysTo ? sysTo : '999' 
                }, diastolic_pressure: { 
                    gte: diaFrom ? diaFrom : '0', 
                    lte: diaTo ? diaTo : '99' 
                } 
            }))
        }
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.systolic_pressure
            delete state.diastolic_pressure
            return state
        })
        setMode(false)
        handleClose(false)
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Stack gap={'12px'} margin={'4px 0px'}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                    <DemoItem label={<Typography sx={labelStyle}>C, мм рт. ст.</Typography>}>
                        <FormControl sx={inputStyle} variant="outlined">
                            <OutlinedInput
                                startAdornment={<InputAdornment position="start"><SystolicPressureIcon/></InputAdornment>}
                                inputProps={{
                                    placeholder: '000',
                                }}
                                value={sysFrom}
                                onChange={(e) => setSysFrom((prev) => {
                                    if (!e.target.value.match(/^(\d?\d?\d?)$/)) return prev
                                    if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                    return e.target.value
                                })}
                            />
                        </FormControl>
                    </DemoItem>

                    <DemoItem label={<Typography sx={labelStyle}>По мм рт. ст.</Typography>}>
                        <FormControl sx={inputStyle} variant="outlined">
                            <OutlinedInput
                                startAdornment={<InputAdornment position="start"><SystolicPressureIcon/></InputAdornment>}
                                inputProps={{
                                    placeholder: '000',
                                }}
                                value={sysTo}
                                onChange={(e) => setSysTo((prev) => {
                                    if (!e.target.value.match(/^(\d?\d?\d?)$/)) return prev
                                    if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                    return e.target.value
                                })}
                            />
                        </FormControl>
                    </DemoItem>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                    <DemoItem label={<Typography sx={labelStyle}>C, мм рт. ст.</Typography>}>
                        <FormControl sx={inputStyle} variant="outlined">
                            <OutlinedInput
                                startAdornment={<InputAdornment position="start"><DiastolicPressureIcon/></InputAdornment>}
                                inputProps={{
                                    placeholder: '00',
                                }}
                                value={diaFrom}
                                onChange={(e) => setDiaFrom((prev) => {
                                    if (!e.target.value.match(/^(\d?\d?)$/)) return prev
                                    if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                    return e.target.value
                                })}
                            />
                        </FormControl>
                    </DemoItem>

                    <DemoItem label={<Typography sx={labelStyle}>По, мм рт. ст.</Typography>}>
                        <FormControl sx={inputStyle} variant="outlined">
                            <OutlinedInput
                                startAdornment={<InputAdornment position="start"><DiastolicPressureIcon/></InputAdornment>}
                                inputProps={{
                                    placeholder: '00',
                                }}
                                value={diaTo}
                                onChange={(e) => setDiaTo((prev) => {
                                    if (!e.target.value.match(/^(\d?\d?)$/)) return prev
                                    if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                    return e.target.value
                                })}
                            />
                        </FormControl>
                    </DemoItem>
                </Box>
            </Stack>

            <Divider sx={{margin: '0px -12px'}}/>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(pressure).map((key, index) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={pressure[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                pressure[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}