import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Popper, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { IReportStatus } from "../../Meds/types"
import { getStatusChip } from "../ReportPageModel"


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const formControlLabelStyle = {
    //margin: '0px'
}

const complaintChipStyle = {
    inlineSize: 'fit-content', maxHeight: '24px',
    backgroundColor: '#009FDA', color: '#FFFFFF',
    fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
    '& .MuiChip-label': { padding: '2px 8px' },
    borderRadius: '4px',
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const ComplaintsFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [complaints, setComplaints] = useState<any>({ 
        'Головная боль': false, 'Зубная боль': false, 'Боль в спине': false, 'Боль в животе': false, 'Другое': false, 
        'Нет': false 
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComplaints({...complaints, [event.target.name]: event.target.checked})
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(complaints).filter((key) => complaints[key] === true)
        console.log(resultArray)
        
        if (resultArray.length === 1 && resultArray[0] === 'Нет') {
            setFilterRequest((prev:any) => ({...prev, driver_health_reports: { none: {} }}))
        } else {
            setFilterRequest((prev:any) => ({...prev, driver_health_reports: { some: { message: { in: resultArray } } }}))
        }
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.driver_health_reports
            return state
        })
        setMode(false)
        handleClose(false)
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <FormControl component="fieldset" variant="standard" sx={{margin: '-5px 0px -5px 0px'}}>
                <FormGroup>
                    {Object.keys(complaints).map((key, index) => (
                        <FormControlLabel key={key}
                            label={
                                <Chip 
                                    label={key}
                                    sx={[complaintChipStyle, key === 'Нет' && { backgroundColor: '#8E8E8E' }]}
                                />
                            }
                            control={
                                <Checkbox 
                                    checked={complaints[key]} onChange={handleChange} name={key}
                                />
                            }
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}