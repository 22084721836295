import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack, Typography } from "@mui/material"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"

export const AlconaftPartyIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10060_309265)">
                <path d="M5.34569 13.0207L3.81141 18.4007M1.69141 17.795L5.92998 19.0035M3.11283 8.58638L5.87712 1.25781L11.0957 2.74353L9.57855 10.4292C9.48978 10.8885 9.3065 11.3243 9.04033 11.7089C8.77417 12.0936 8.43095 12.4187 8.03245 12.6636C7.63394 12.9085 7.18887 13.0679 6.72549 13.1317C6.2621 13.1954 5.79052 13.1621 5.34068 13.0339C4.89084 12.9057 4.47258 12.6854 4.11243 12.3869C3.75229 12.0884 3.45813 11.7183 3.24864 11.3001C3.03915 10.8819 2.9189 10.4247 2.89552 9.95752C2.87214 9.49035 2.94614 9.02343 3.11283 8.58638Z" stroke="#787878" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.49805 4.91016L10.3352 6.58873" stroke="#787878" stroke-width="1.42857"/>
                <path d="M12.2383 12.8035C12.8455 13.1031 13.5287 13.2131 14.1992 13.1192C14.8697 13.0252 15.4965 12.7318 15.998 12.277C16.4995 11.8221 16.8526 11.2269 17.0114 10.5687C17.1702 9.91057 17.1273 9.21986 16.8883 8.58638L14.1211 1.25781L13.2983 1.49067M14.654 13.0207L16.1883 18.4007M18.3083 17.795L14.0697 19.0035M15.5026 4.92781L13.1183 5.61353" stroke="#787878" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_10060_309265">
                <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px 8px 0px -4px',
        pointerEvents: 'none',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}


interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const AlcoholFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [pmmFrom, setPmmFrom] = useState<string>('')
    const [pmmTo, setPmmTo] = useState<string>('')

    const [pmm, setPmm] = useState<any>({ 
        'Трезвый (0 промилле)': false, 'От 0.5 до 1.5': false, 'От 1.51 до 2.5': false, 'От 2.5 до 3.0': false, 'От 3.0 до 5.0': false 
    })

    const pmmValues: any = {
        'Трезвый (0 промилле)': {gte: '0.0', lte: '0.0'}, 
        'От 0.5 до 1.5': {gte: '0.5', lte: '1.5'}, 
        'От 1.51 до 2.5': {gte: '1.51', lte: '2.5'}, 
        'От 2.5 до 3.0': {gte: '2.5', lte: '3.0'}, 
        'От 3.0 до 5.0': {gte: '3.0', lte: '5.0'}, 
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPmm(Object.entries(pmm).forEach(([key]) => {pmm[key] = false}))
        setPmm({...pmm, [event.target.name]: event.target.checked})

        setPmmFrom(pmmValues[event.target.name].gte)
        setPmmTo(pmmValues[event.target.name].lte)  
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(pmm).filter((key) => pmm[key] === true)
        console.log(resultArray)

        if (pmmFrom || pmmTo) {
            setFilterRequest((prev:any) => ({...prev, alcohol_content: { 
                gte: pmmFrom ? pmmFrom : '0.0',
                lte: pmmTo ? pmmTo : '9.9'
            }}))
        }
        setMode(true)
        handleClose(true)
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.alcohol_content
            return state
        })
        setMode(false)
        handleClose(false)
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px', margin: '4px 0px'}}>
                <DemoItem label={<Typography sx={labelStyle}>С, промилле</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><AlconaftPartyIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '0.00',
                            }}
                            value={pmmFrom}
                            onChange={(e) => setPmmFrom((prev)=>{
                                if (!e.target.value.match(/^(?!\.)(\d?)(\.?)(\d?\d?)$/)) return prev
                                if (e.target.value[2] === '.') return prev
                                if (e.target.value.length === 2 && e.target.value[1] !== '.') return [e.target.value.slice(0, 1), '.', e.target.value.slice(1)].join('')
                                if (e.target.value.length === 2) return e.target.value.slice(0, -1); 
                                return e.target.value 
                            })}
                        />
                    </FormControl>
                </DemoItem>

                <DemoItem label={<Typography sx={labelStyle}>По, промилле</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><AlconaftPartyIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '0.00',
                            }}
                            value={pmmTo}
                            onChange={(e) => setPmmTo((prev)=>{
                                if (!e.target.value.match(/^(?!\.)(\d?)(\.?)(\d?\d?)$/)) return prev
                                if (e.target.value[2] === '.') return prev
                                if (e.target.value.length === 2 && e.target.value[1] !== '.') return [e.target.value.slice(0, 1), '.', e.target.value.slice(1)].join('')
                                if (e.target.value.length === 2) return e.target.value.slice(0, -1); 
                                return e.target.value 
                            })}
                        />
                    </FormControl>
                </DemoItem>
            </Box>

            <Divider sx={{margin: '0px -12px'}}/>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(pmm).map((key, index) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={pmm[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                pmm[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}