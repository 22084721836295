import { Box, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { tableHeaderText } from "../../../styles/MaterialCustomStyles";
import { IMedTableData, INormTable } from '../types';


interface IMedTableDataProps {
    actualData: IMedTableData | null,
    normsData: INormTable | null,
    coverBgColor?: any
    coverPadding?: any
    textSize?: any
    textGap?: any
    hideTitle?: boolean
    cardBorderRadius?: string;
    fontSize?: string
}

export const MedDataTable = ({
  actualData, normsData, coverBgColor, coverPadding, textSize, textGap, hideTitle = false,
  cardBorderRadius, fontSize,
}: IMedTableDataProps) => {

    //console.log('actualData')
    //console.log(actualData)
    const statusStyle = {
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'lowercase',
        border: '1px solid',
        borderRadius: '2px',
        padding: '1px 8px',
        width: 'max-content',
        display: 'inline-block',
    };

    const confirmedStyle = {
        ...statusStyle,
        color: '#52C41A',
        borderColor: '#B7EB8F',
        backgroundColor: '#F6FFED',
    };

    const draftStyle = {
        ...statusStyle,
        color: '#2F54EB',
        borderColor: '#ADC6FF',
        backgroundColor: '#F0F5FF',
    };

    const deletedtStyle = {
        ...statusStyle,
        color: '#F5222D',
        borderColor: '#FFA39E',
        backgroundColor: '#FFF1F0',
    };


    const indicatorsStyle = {
        fontWeight: 500,
        fonntSize: '16px',
        color: '#33C341'
    }

    const outOfRangeIndicatorStyle = {
        fontWeight: 500,
        fonntSize: '16px',
        color: 'red'
    }


    const tableTitleStyle = {
        fontSize: '14px',
        fontWeight: '500'
    }



    const dataContainerStyle = {
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
    }

    const containerTableHeadingStyle = {
        backgroundColor: '#EEEEEE',
        padding: '8px 16px',
        fontWeight: '500',
        fontSize: '14px',
        color: '#565656',
    }

    const containerTableRowStyle = {
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #EEEEEE',
            padding: '8px 16px',
            height: '35px'
        },
        backgroundColor: '#FFFFFF'
    }

    const containerTableDataStyle = {
        padding: '8px 16px',
        fontWeight: '400',
        fontSize: fontSize ? fontSize : '14px',
        color: '#2A2A2A',
    }



    const checkIndicator = (current: string | null | undefined, from: string | null | undefined, to: string | null | undefined) => {
        if (!!current && !!from && !!to) {
            if (Number(current) >= Number(from) && Number(current) <= Number(to)) {
                return true
            }
        } else return false
    }

    return (
        <Card sx={{
            boxShadow: 'none', padding: coverPadding ?? '16px',
            display: 'flex', flexDirection: 'column', gap: textGap ?? '16px',
            backgroundColor: coverBgColor ?? 'white',
            height: '100%',
            borderRadius: cardBorderRadius ?? '4px',
        }}>
            {!hideTitle && (
              <h3 style={{
                fontFamily: 'Roboto',
                fontSize: textSize ?? '20px',
                fontWeight: '500',
                lineHeight: '26px',
                color: '#3E3E3E'
              }}>
                  Данные медосмотра
              </h3>
            )}

            <TableContainer sx={{ ...dataContainerStyle }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{
                                ...containerTableHeadingStyle,
                                ...containerTableRowStyle,
                                //backgroundColor: '#FAFAFA',
                            }}
                        >
                            <TableCell
                                sx={{
                                    ...containerTableHeadingStyle
                                }}
                                align="left"
                              >
                                Наименование
                              </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    ...containerTableHeadingStyle
                                }}
                            >

                                Показатели

                            </TableCell>
                            <TableCell
                                sx={{
                                    ...containerTableHeadingStyle
                                }}
                                align="left">Норма</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >

                                Тип осмотра


                                {/* <Typography sx={{fontWeight:'600',}}>Название</Typography> */}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: '500',
                                    color: '#565656',
                                }}
                                align="left">{actualData?.type}</TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: '500',
                                    color: '#565656',
                                }}
                                align="left"></TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle,
                                // '& .MuiTableCell-root': {
                                //     padding: '8px 16px',
                                //     height: '37px'
                                // }
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >
                                Содержание алкоголя мг/л

                            </TableCell>
                            <TableCell
                                align="left"
                                sx={
                                    checkIndicator(actualData?.currentAlcoholContent,
                                        normsData?.alcohol?.value_from,
                                        normsData?.alcohol?.value_to) ?
                                        indicatorsStyle : outOfRangeIndicatorStyle
                                }
                            >
                                {actualData?.currentAlcoholContent}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                  ...indicatorsStyle,
                                  color: '#787878'
                                }}
                            >
                                {
                                    normsData !== null &&
                                    `(${normsData?.alcohol?.value_from})`
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >

                                Давление SIS/DIA


                            </TableCell>
                            <TableCell
                                sx={{
                                    ...((checkIndicator(actualData?.currentSystolicPressure,
                                        normsData?.systolicPressure?.value_from,
                                        normsData?.systolicPressure?.value_to) &&
                                        checkIndicator(actualData?.currentDiastolicPressure,
                                        normsData?.diastolicPressure?.value_from,
                                        normsData?.diastolicPressure?.value_to)) ?
                                        indicatorsStyle : outOfRangeIndicatorStyle)
                                }}
                                align="left">
                                {actualData?.currentSystolicPressure ? `${actualData?.currentSystolicPressure}/` : ''}{actualData?.currentDiastolicPressure}
                            </TableCell>
                            {/* {`${} до ${}`} */}
                            <TableCell
                                sx={{
                                  ...indicatorsStyle,
                                  color: '#787878'
                              }}
                                align="left">
                                {
                                    normsData !== null &&
                                    `(${normsData?.systolicPressure?.value_from}-${normsData?.systolicPressure?.value_to}/${normsData?.diastolicPressure?.value_from}-${normsData?.diastolicPressure?.value_to})`
                                }


                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >

                                Температура C


                            </TableCell>
                            <TableCell
                                align="left"
                                sx={
                                    checkIndicator(actualData?.currentTemperature,
                                        normsData?.temperature?.value_from,
                                        normsData?.temperature?.value_to) ?
                                        indicatorsStyle : outOfRangeIndicatorStyle
                                }
                            >{actualData?.currentTemperature}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                  ...indicatorsStyle,
                                  color: '#787878'
                                }}
                            >
                                {
                                    normsData !== null &&
                                    `(${normsData?.temperature?.value_from} - ${normsData?.temperature?.value_to})`
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >

                                Частота пульса уд/мин


                            </TableCell>
                            <TableCell
                                align="left"
                                sx={
                                    checkIndicator(actualData?.currentPulseRate,
                                        normsData?.pulse?.value_from,
                                        normsData?.pulse?.value_to) ?
                                        indicatorsStyle : outOfRangeIndicatorStyle
                                }
                            >{actualData?.currentPulseRate}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                  ...indicatorsStyle,
                                  color: '#787878'
                                }}
                            >
                                {
                                    normsData !== null &&
                                    `(${normsData?.pulse?.value_from} - ${normsData?.pulse?.value_to})`
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                //backgroundColor: '#FAFAFA',
                                ...containerTableRowStyle,
                                '& .MuiTableCell-root': {
                                  padding: '8px 16px',
                                  height: '37px',
                                  borderBottom: 'none',
                                }
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={
                                    containerTableDataStyle
                                }
                            >

                                Жалобы


                            </TableCell>
                            <TableCell
                                sx={
                                    outOfRangeIndicatorStyle
                                }
                                align="left">
                                {
                                    !!actualData?.driverHealthReports ?
                                        actualData?.driverHealthReports.length > 0 ? actualData?.driverHealthReports.map((element: any) => {
                                            return <Typography>
                                                {element.message}
                                            </Typography>
                                        }) : <Typography sx={{color: '#33C341'}}>нет</Typography>
                                        : 'нет'
                                }
                            </TableCell>
                            <TableCell
                                sx={{
                                    ...indicatorsStyle,
                                    color: '#787878'
                                }}
                                align="left">
                                  нет
                                </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>

    )



}